import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import {OrganizationContext} from '../../../../context/Organization/Organization';
import { SensorsContext } from '../../../../context/Devices/Sensors';
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import Swal from '@molline/sweetalert2';
import {useRouter} from "../../../../components/Shared/Router/Router";
import {NavLink} from "react-router-dom";
import { PermissionsWrapper } from '../../../../components/Dashboard/reactPermissionsWrapper';
import { LocationsContext } from '../../../../context/Locations/Locations';
import de from '../../../../components/Dashboard/tables/datatable.de.json';

const $ = require('jquery')
$.DataTable = require('datatables.net');

const SensorCreator = (props) => {

    const {user} = useContext(AuthContext);
    const {getAllLocations, locations} = useContext(LocationsContext)
    const {organizations, organization} = useContext(OrganizationContext);
    const organizationsChanged = useCompare(organizations);
    const {createSensor} = useContext(SensorsContext)
    const router = useRouter()
    const [location, setLocation] = useState(localStorage.getItem('location'));
    const [tabActive, setTabActive] = useState('sensor-details-vertical')

    const initialForm =  {
        serial_number: "",
        symetric_key: "",
        location_id: "",
        description: "",
        tags: [],
        attributes: [],
        factor: "1",
        external_reference_id: "",
        import_reference: "sensor_creator",
        realm: "molline",
        organization_id: organization._id,
    }

    const [formValues, setFormValues] = useState(initialForm)

    useEffect(() => {
        if(location !== null && typeof location === 'string'){
            let l = JSON.parse(location)
            setLocation(l)
        }
   
    }, [tabActive, location, organizationsChanged, organizations, organization, locations]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const resetForm = () => {
        setFormValues(initialForm)
        document.getElementById('org-create-frm').reset()
    }

    const updateForm = (key, value) => {
        if(key === "tags" || key === "attributes") {
            value = value.split(',')
        }
        setFormValues({ ...formValues, [key]: value })
    }

    const createNewSensor = async () => {
        try {
            await createSensor(formValues)
            resetForm()
        } catch (err) {
            console.log('error at creating new sensor', err);
        }
    }

    const tryChangeLocation = async () => {
        let selectedLocation = await showChangeLocationModal();
        if (selectedLocation && selectedLocation != "") {
            updateForm('location_id', selectedLocation)
        }
    }

    const showChangeLocationModal = async () => {
        let modalLocations = locations
            try {
                modalLocations = await getAllLocations({organization_id: formValues.organization_id, filter: false}) 
            } catch (e) {
                console.log('errror at getting locations for location', e);
                return
            }
        return new Promise((resolve, reject) => {
            const changePopup = Swal.fire({
                width: "98%",
                title: `Nutzer-/Mieteinheit suchen`,
                allowOutsideClick: true,
                backdrop: true,
                allowEscapeKey: true,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'Abbrechen',
                html:
                    `<table id="tblSelectLocations" className="table mb-0 row-border hover order-column " role="grid" width="100%">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Alias</th>
                                <th>Straße</th>
                                <th>Hausnr.</th>
                                <th>Wohnung</th>
                                <th>Stadt</th>
                                <th>PLZ</th>
                                <th>Land</th>
                                <th></th>
                            </tr>
                        </thead>
                    </table>`,
                willOpen: () => {
                    initDT({
                        id: '#tblSelectLocations',
                        data: modalLocations,
                        columns: [
                            { "data": "_id" },
                            {
                                "data": "nickname",
                                render: (data) => capitalize(data)
                            },
                            {
                                "data": "address.street",
                                render: (data) => capitalize(data)
                            },
                            { "data": "address.house_number" },
                            { "data": "address.street1" },
                            {
                                "data": "address.city",
                                render: (data) => capitalize(data)
                            },
                            { "data": "address.post_code" },
                            {
                                "data": "address.country",
                                render: (data) => data.toLocaleUpperCase()
                            },
                            {
                                data: "null", render: function (data, type, row, meta) {
                                    return `<div style="text-align:right"><button class="btn btn-primary btn-small" id="location-select-btn" data-location_id=${row._id}>Auswählen</button></div>`
                                }
                            },
                        ],
                        options: {
                            rowId: "_id",
                            columnDefs: [{ "defaultContent": "-", "targets": "_all" }],
                            drawCallback: function (settings) { //to make it work on pagination
                                $('#tblSelectLocations tbody tr').off('mouseenter').on('mouseenter', function () {
                                    $(this).css('cursor', 'pointer');
                                    $(this).css('background-color', '#f3f2f7');
                                });

                                $('#tblSelectLocations tbody tr').off('mouseleave').on('mouseleave', function () {
                                    $(this).css('background-color', '#fff');
                                });

                                $('#tblSelectLocations tbody tr').off('click').on('click', function () {
                                    const button = $(this).find('button').first();
                                    if (button && button.get(0)) {
                                        let location_id = button.get(0).dataset.location_id;
                                        resolve(location_id);
                                        changePopup.close();
                                    }
                                });
                            }
                        },
                        force: true
                    })

                }
            })

        });
    }

    const capitalize = (str) => {
        if(!str) return str;
        if (str.indexOf("-") > -1)
            return str.split("-").map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join("-")
        return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
    }

    const initDT = ({ id, data, columns = [], options = {}, force = false }) => {
        //you need to force init if the table is still hidden, e.g. on prerender of swal
        //console.log("trying to initialize datatable", id, $.fn.DataTable.isDataTable(id), data, columns)
        if (!data || !force && data.length <= 0 || $.fn.DataTable.isDataTable(id) || !force && !$(id).is(":visible"))
            return;
        let table = $(id).DataTable({
            "language": de,
            "processing": true,
            "data": data,
            "rowId": "_id",
            "order": [[0, "desc"]],
            "pageLength": 25,
            "columns": columns,
            "drawCallback": function (settings) {
                $('#tblSelectLocations tbody tr').off('mouseenter').on('mouseenter', function () {
                    $(this).css('cursor', 'pointer');
                    $(this).css('background-color', '#f3f2f7');
                });

                $('#tblSelectLocations tbody tr').off('mouseleave').on('mouseleave', function () {
                    $(this).css('background-color', '#fff');
                });
            },
            ...options
        });
    }

    const UI  = () => {
        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Sensor Registrieren</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Apps</a>
                                        </li>
                                        <li className="breadcrumb-item"><NavLink to="/apps/sensors"><a >Sensoren</a></NavLink>
                                        </li>
                                        <li className="breadcrumb-item active">Sensor Registrieren
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>
                <div className="content-body">
                    <section className="vertical-wizard">
                        <div className="bs-stepper vertical vertical-wizard-example">
                            <div className="bs-stepper-header">

                                <div className={`step ${(tabActive === 'sensor-details-vertical')? 'active':''}`} data-target="#sensor-details-vertical" role="tab" id="sensor-details-vertical-trigger" onClick={() => setTabActive('sensor-details-vertical')}>
                                    <button type="button" className="step-trigger">
                                        <span className="bs-stepper-box">1</span>
                                        <span className="bs-stepper-label">
                                            <span className="bs-stepper-title">Einzelregistierung</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="bs-stepper-content">
                                <form className='row' id="org-create-frm">
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Seriennummer*</label>
                                        <input type="text" className="form-control" defaultValue={formValues.serial_number} required onChange={(e) => updateForm('serial_number', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">AES-Key</label>
                                        <input type="text" className="form-control" defaultValue={formValues.symetric_key} required onChange={(e) => updateForm('symetric_key', e.target.value)} />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label" htmlFor="overviewLocation">Nutzer-/Mieteinheit</label>
                                        <div className="input-group">
                                            <span className="input-group-text"><i class="fa-solid fa-house"></i></span>
                                            <input type="text" className="form-control" id="overviewLocation" name="location" onClick={tryChangeLocation} 
                                            value={
                                                formValues.location_id}  />
                                        </div>

                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Beschreibung</label>
                                        <input type="text" className="form-control" defaultValue={formValues.description} required onChange={(e) => updateForm('description', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Attribute</label>
                                        <input type="text" className="form-control" defaultValue={formValues.attributes} required onChange={(e) => updateForm('attributes', e.target.value)} />
                                        <small>Mehrere Attribute Bitte mit Komma und ohne Leerzeichen trennen!</small>
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Tags</label>
                                        <input type="text" className="form-control" defaultValue={formValues.tags} required onChange={(e) => updateForm('tags', e.target.value)} />
                                        <small>Mehrere Tags Bitte mit Komma und ohne Leerzeichen trennen!</small>
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Faktor</label>
                                        <input type="text" className="form-control" defaultValue={formValues.factor} required onChange={(e) => updateForm('factor', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Token (externe ID)</label>
                                        <input type="text" className="form-control" defaultValue={formValues.external_reference_id} required onChange={(e) => updateForm('external_reference_id', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Realm*</label>
                                        <input type="text" className="form-control" defaultValue={formValues.realm} required disabled={true} />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label" htmlFor="organization">Kunde*</label>
                                        <PermissionsWrapper
                                            user={user}
                                            scopes={['location.create.any', 'location.update.any']}
                                            errorProps={{ disabled: true }}
                                        >
                                            <select className="select2 form-select" name="organization_id" id="overviewOrganization" onChange={(e) => updateForm('organization_id', e.target.value)}>
                                                {
                                                    organizations.map(org => {
                                                        return (
                                                            <option key={org._id} value={org._id} selected={formValues.organization_id === org._id }>{org.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </PermissionsWrapper>
                                    </div>
                                </form>
                                <div className='row'>
                                    <div className='col-12'>
                                        <button className='btn btn-primary me-1' onClick={createNewSensor}>Erstellen</button>
                                        <button className='btn btn-secondary' onClick={resetForm}>Zurücksetzen</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )



    }

    return (
        <Fragment>
           

            {UI()}
        </Fragment>
    )
  

}


SensorCreator.requiredScope = ['sensor.create.any', 'sensor.create.own']
SensorCreator.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default SensorCreator;
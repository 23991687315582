import React, {createContext, Fragment, useContext, useEffect, useRef, useState} from 'react';
import cookies from 'js-cookie';
import Axios_client from "../../utils/axios_client";
import {AuthContext} from '../Auth/AuthProvider';
import {LocationsContext} from '../Locations/Locations';
import {hasPermission} from "../../components/Dashboard/reactPermissionsWrapper";
import {OrganizationContext} from '../Organization/Organization';
import {GroupsContext} from "../Groups/Groups";
import Swal from "@molline/sweetalert2";

export const SensorsContext = createContext();

const SensorsProvider = ({ children, context }) => {

    const { user } = useContext(AuthContext);

    const [sensors, setSensors] = useState([]);
    const [sensorMeasurements, setSensorMeasurements] = useState([]);
    const [sensorTelegrams, setSensorTelegrams] = useState([]);
    const [sensor, setSensor] = useState({})
    const [groupSensors, setGroupSensors] = useState([]);
    const { group } = useContext(GroupsContext);
    const { locations } = useContext(LocationsContext);
    const userChanged = useCompare(user)

    const { organization } = useContext(OrganizationContext);
    const organizationChanged = useCompare(organization)
    const sensorsChanged = useCompare(sensors)


    const locationsChanged = useCompare(locations)

    useEffect(  () => {

        if( userChanged === true || organizationChanged === true || locationsChanged === true){
            getAllSensors()
        }

    }, [user, sensors, sensor, group, organization, organizationChanged, locations, locationsChanged] )

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const errorToast = (err) => {
        if(!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }

    const successToast = (msg) => {
        if(!msg) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success text-white"
            }
        })
    }

    const doYouReallyWantToSave = () => {
        return new Promise(async (resolve) => {
            const answer = await Swal.fire({
                title: 'Sicher?',
                text: 'Damit werden die Daten gespeichert',
                showCancelButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText: "Nein",
            })
            if (!answer.isConfirmed) return resolve(false);
            return resolve(true);
        })
    }
    /**
     * There are instances like reports when we do not want to reveal all sensor data to
     * the user. Clean that data using this method and return a sensor object.
     */
    const sanitizedSensor = (ssr) => {
        let modified = { ...ssr }
        delete modified.symetric_key
        delete modified.realm
        delete modified.comments
        delete modified.logs
        delete modified.manufacturer
        delete modified.hash_id
        delete modified.__v
        delete modified.location
        delete modified.tags
        delete modified.attributes
        delete modified.import_reference
        delete modified.consumption

        if (modified.state !== undefined && modified.state.date !== undefined) {
            modified.last_transmission = modified.state.date
            modified.value = modified.state.value
        }
        delete modified.state
        if (modified.eras !== undefined) {
            if (modified.eras.number !== undefined) modified.eras_number = modified.eras.number
            if (modified.eras.id !== undefined) modified.eras_id = modified.eras.id
        }

        if (ssr.location_id !== "" && ssr.location !== undefined) {
            let add = ssr.location.address
            modified.location = `${add.street} ${add.house_number}, ${add.street1}, ${add.city}, ${add.post_code}`
        }
        return modified;
    }
/**
     * The current code allows for rendering inside dataTables
     * To render the same html inside of JSX you must use:
     * <span dangerouslySetInnerHTML={{__html:sensorTypeIcon({sensor, size:6})}}></span>
     */
    const sensorTypeIcon = ({sensor, size = 1}) => {
        if(sensor.serial_number === undefined) return ''
        if(sensor.attributes.includes('wmbus')) return ( `<i title="Funk-Zähler (WMBUS)" class="fa-solid fa-wifi text-success fa-${size}x" ></i>` )
        else if(sensor.attributes.includes('mbus')) return ( `<i title="Drahtgebundener Zähler (MBUS)" class="fa-solid fa-diagram-nested fa-${size}x text-primary" f></i>` )
        else if(sensor.attributes.includes('lora')) return ( `<i title="LORA-Zähler" class="fa-solid fa-tower-broadcast fa-${size}x text-primary" ></i>` )
        else return ( `<i class="fa-solid fa-wifi-exclamation fa-${size}x text-danger" title="Sensor-Typ unbekannt (kein WMBUSMBUS/LORA)" ></i>` )
    }

    const roundDecimal = (value) => {
        if(value >= 99 && value < 100) return 99;
        const  decimalPart = value - Math.floor(value);
        if(decimalPart < 0.5) {
            return Math.floor(value)
        } else {
            return Math.ceil(value);
        }
    }
    
    /**
     * The current code allows for rendering inside dataTables
     * To render the same html inside of JSX you must use:
     * <span dangerouslySetInnerHTML={{__html:medium_icon({sensor, size:6})}}></span>
     */
    const medium_icon = ({sensor, size = 1}) => {
        if( sensor.medium_code === '0x8' ) return `<i title="Heizkostenverteiler (HKVE)" class="text-danger fa-solid fa-fire fa-${size}x"></i>`;
        if( ['0x4', '0xc'].includes(sensor.medium_code ) ){
            if( !!sensor.attributes && sensor.attributes.includes('wsr') ) return `<i title="Haupt-Warmwasserzähler (WMZ)" class="text-danger fa-solid fa-droplet fa-${size}x"></i>`;
            if( !!sensor.attributes && sensor.attributes.includes('hzg') ) return `<i title="Haupt-Heizungszähler (WMZ)" class="text-danger fa-solid fa-fire fa-${size}x"></i>`;
            if( !!sensor.attributes && sensor.attributes.includes('wmz_wsr') ) return `<i title="WMZ für Warmwasser" class="text-warning fa-solid fa-droplet-degree fa-${size}x"></i>`;
            return `<i title="Heizungszähler (WMZ)" class="text-warning fa-solid fa-fire fa-${size}x"></i>`;
        }
        if( sensor.medium_code === '0x6' ) return `<i title="Warmwasserzähler (WWZ)" class="text-warning fa-solid fa-droplet fa-${size}x"></i>`;
        if( ['0x7', '0x16'].includes(sensor.medium_code) ) return `<i title="Kaltwasserzähler (KWZ)" class="text-primary fa-solid fa-droplet fa-${size}x"></i>`;
        if(sensor.medium_code === '0x1a') return `<i title="Rauchmelder (SMK)" class="text-secondary fa-solid fa-fire-smoke fa-${size}x"></i>`;
        if( ['0xa', '0xb'].includes(sensor.medium_code) ) return `<i title="Kühllast Vorlauf/Rücklauf Zähler (KHR, KHV)" class="text-primary fa fa-snowflake fa-${size}x"></i>`;
        /*if( ['0xd'].includes(sensor.medium_code) ) return `<i title="Heiz/Kühllast Zähler (CMB)" class="text-danger fa fa-snowflake fa-${size}x"></i>`;*/
        if( ['0x3'].includes(sensor.medium_code) ) return `<i title="Gaszähler (GAS)" class="text-primary fa-solid fa-wind fa-${size}x"></i>`;
        if( ['0x2'].includes(sensor.medium_code) ) return `<i title="Stromzähler (ELEC)" class="text-primary fa-solid fa-bolt fa-${size}x"></i>`;
        if( ['0x1'].includes(sensor.medium_code) ) return `<i title="Ölzähler" class="text-primary fa-solid fa-oil-can fa-${size}x"></i>`;
        return `<i title="Unbekannter Zähler" class="text-primary fa-solid fa-microchip fa-${size}x"></i>`;
    }

    const sensorFlags = ({sensor, size = 1}) => {
        let flags = '';
        if (sensor?.state?.flags && sensor.state.flags.length > 0) {
            let flagsStr = JSON.stringify(sensor.state.flags)
            if( hasPermission({user, scopes:['error.view.any']}) ) {
                 if (flagsStr.search('null_measurement') > -1) flags += `<i class="fa-solid fa-scale-balanced text-danger fa-${size}x" title="Zähler hat NULL-Messwert"></i>`;
                 if (flagsStr.search('decryption') > -1) flags += `<i class="fa-solid fa-key-skeleton text-danger fa-${size}x" title="Zähler konnte nicht entschlüsselt werden"></i>`;
                 if (flagsStr.search('error_report') > -1) flags += `<i class="fa-solid fa-triangle-exclamation text-danger fa-${size}x" title="Zähler hat einen Fehlerbericht"></i>`;
            }
            if( hasPermission({user, scopes:['error.view.any']}) ) {
                if (flagsStr.search('handicap') > -1) flags += `<i class="fa-solid fa-screwdriver-wrench text-primary fa-${size}x" title="Zähler hat einen Fehler-Status"></i>`;
            }


        }
        let timeFlag = null
        if( hasPermission({user, scopes:['error.view.any']}) ) {
            if (sensor?.state?.date) {
                let date = new Date()
                let sDate = new Date(sensor.state.date)
                let hours = Math.abs(date - sDate) / 36e5;

                if (hours > 120) { timeFlag = `<i class="fa-solid fa-clock  fa-${size}x text-warning" title="Keine Daten von diesem Zähler seit 5 Tagen oder mehr"></i>` }
                if (hours > 360) { timeFlag = `<i class="fa-solid fa-clock  fa-${size}x text-danger" title="Keine Daten von diesem Zähler seit 15 Tagen oder mehr"></i>` }
            } else {
                timeFlag = `<i class="fa-solid fa-clock  fa-${size}x text-danger" title="Keine Daten von diesem Zähler seit 15 Tagen oder mehr"></i>`;
            }
        }


        if (!!timeFlag) flags += timeFlag
        return flags
    }
    /**
     * In the application, when a user selcts a sensor, make that sensor the context
     * which the user is working in, then get all resources
     * for that sensor here.
     */

    const getSensor = async (serial_number) => {
        return new Promise( (resolve, reject) => {
            if( hasPermission({user, scopes:['sensor.view.any', 'sensor.view.own']}) ){
                Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/sensor?serial_number=${serial_number}`)
                .then( async response => {
                    let sensorResults = {...response.data}
                    if(locations && locations.length > 0){
                        try{sensorResults.location = locations.filter(l => l._id === sensorResults.location_id)[0]}catch(err){}
                    }
                    setSensor(sensorResults)
                    await getSensorMeasurements(serial_number)
                    return resolve(sensorResults)
                })
                .catch(err => {
                    console.log('ERROR 45 ',err)
                    return reject(err)
                })
            }
            return resolve([]);
        })
    }

    const getAllSensors = () => {
        if(!organization._id){
            console.log(`ORGAINZATION CONTEXT: ID NOT FOUND`)
            setSensors([]);
            return;
        }
        if( user && hasPermission({user, scopes:['sensor.view.any', 'sensor.view.own']}) ){
            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/sensor/all?organization_id=${organization._id}`)
            .then( async response => {
                let filterArray = []
                // reason for group condition: show no sensors if the group has no locations
                if(locations.length === 0 && Object.keys(group).length === 0) filterArray = [...response.data]
                for(const loc of locations){
                    let filtered = response.data.filter(sen => loc.sensors.includes(sen.serial_number))
                    filterArray = filterArray.concat(filtered)
                }
                setSensors(filterArray)
                return filterArray;

            })
            .catch( err => {
                console.log('SENSORS FETCHING ERROR 39', err)
                return 'SENSORS FETCHING ERROR 39'
            })
        }
    }
    
    const getSensorMeasurements = (serial_number, year) => {
        return new Promise( async (resolve, reject) => {
            if(!serial_number) return reject("serial_number required");
            if(!year) year = new Date().getFullYear()
            if( user && hasPermission({user, scopes:['sensor.view.any', 'sensor.view.own']}) ){
                Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/sensor/measurements?serial_number=${serial_number}&year=${year}`)
                .then( response => {
                    if(response.data !== undefined){
                        setSensorMeasurements(response.data)
                        return response.data
                    }else{
                        setSensorMeasurements([])
                        return []
                    }
                })
                .catch( err => {
                    console.log('SENSORS FETCHING ERROR 39', err)
                })
            }
        })
    }

    const getTelegram = (telegram_id) => {
        return new Promise( async (resolve, reject) => {
            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/sensor/telegram?telegram_id=${telegram_id}`)
            .then( response => {
                return resolve(response.data)
            })
            .catch(err => {
                errorToast('Telegramm ist nicht verfügbar');
                return reject(err)
            })
        })
    }

    const updateSensor = async (sensor) => {
        Axios_client.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/sensor`, sensor)
        .then( response => {
            setSensor(response.data)
            getAllSensors();
        })
        .catch( err => {
            console.log('SENSORS UPDATE ERROR 152', err)
            return 'SENSORS UPDATE ERROR 152'
        })
    }

    const deleteSensor = (serial_number) => {
        return new Promise(async (resolve, reject) => {
            const answer = await Swal.fire({
                title: 'Sicher?',
                text: 'Wollen SIe wirklich diesen Sensor löschen??',
                showCancelButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText: "Nein",
            })
            if (!!answer.isConfirmed){
                Axios_client.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/sensor`, {data:{serial_number}})
                .then( response => {
                    getAllSensors();
                    successToast(response.data)
                    return resolve(response.data)
                })
                .catch( err => {
                    console.log('SENSORS UPDATE ERROR 152', err)
                    errorToast(`Zähler ${serial_number} konnte nicht gelöscht werden: ${err}`)
                    return reject('SENSORS UPDATE ERROR 152')
                })
            }
        })
       
    }
    /**
     * Gets the stitchtag or end of year report for an organization
     */
    const annualClosingReport = () => {
        return new Promise((resolve, reject) => {
            if(!hasPermission({user, scopes:['sensor.view.any', 'sensor.view.own']}))
            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/reports/annual_reading`)
            .then( response => {
                return resolve(response.data)
            })
            .catch( err => {
                console.log('SENSORS REPORT ERROR 171', err)

            })
        })
    }

    const addComment = async (params) => {
        Axios_client.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/sensor/comment`, params)
        .then( (response) => {
            getAllSensors();
            setSensor(response.data)
        })
        .catch((err) => {

        })       
    }

    const deleteComment = async (params) => {
        Axios_client.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/sensor/comment`, { data: params })
        .then(response => {
            getAllSensors();
            setSensor(response.data)
        })
    }

    const replaceSensor = ({sensorOld, sensorNew}) => {
        return new Promise((resolve, reject) => {
            console.log('REPLACING')
            Axios_client.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/sensor/replace`, {sensorOld: sensorOld.serial_number, sensorNew:sensorNew.serial_number, newOffset: sensorOld.state.value} )
            .then(response => {
                
                console.log(response)
                return resolve(response.data)
            })
            .catch(err => {

                console.log(err.message)
                return reject(err.message)
            })
        })
    }

    const createSensor = async (newSensor) => {
        console.log('here');
        return new Promise(async (resolve, reject) => {
            console.log('newSensor', newSensor);
            const sure = await doYouReallyWantToSave()
            if (sure){
                try {
                    await Axios_client.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/sensor`, newSensor)
                    successToast('Sensor wurde erfolgreich erstellt')
                    getAllSensors()
                    return resolve('success')
                } catch (err) {
                    console.log('error at creating sensor', err);
                    errorToast('Fehler beim Erstellen des Sensors')
                    return reject(err)
                }
                
            }
        })
    }

    const actions = {
        sensor,
        sensors,
        sensorMeasurements,
        sensorTelegrams,
        getSensor,
        setSensors,
        setSensor,
        getSensorMeasurements,
        getAllSensors,
        annualClosingReport,
        groupSensors,
        addComment,
        deleteComment,
        updateSensor,
        deleteSensor,
        medium_icon,
        sensorFlags,
        sensorTypeIcon,
        getTelegram,
        replaceSensor,
        doYouReallyWantToSave,
        errorToast,
        successToast,
        sensorsChanged,
        sanitizedSensor,
        roundDecimal,
        createSensor
    }

    return (
        <Fragment>
            <SensorsContext.Provider value={actions}>
                {children}
            </SensorsContext.Provider>
        </Fragment>
    )

}

export default SensorsProvider;
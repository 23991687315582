import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { useRouter } from "../../../Shared/Router/Router";
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import { AuthContext } from '../../../../context/Auth/AuthProvider';
import {TenantContext} from '../../../../context/Tenant/Tenant';
import {LocationsContext} from '../../../../context/Locations/Locations';
import { Link } from "react-router-dom";

import de from '../datatable.de.json'

import capitalizeUtil from '../../../../utils/capitalizeUtils'
import Organizations from "../../../../pages/Dashboard/admin/organizations/organizations";
import {OrganizationContext} from "../../../../context/Organization/Organization";

const $ = require('jquery')
$.DataTable = require('datatables.net');

/**
 * This table is used multiple times. A props.id is REQUIRED
 * when calling this component
 */
const TblLocations = (props) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const { locations, locationIcon, groupLocations, deleteLocation, removeParentLocation, removeChildLocation, location, moveLocation, toggleUviAttribute, deleteCompleteLocation, toggleBillingCustomer, toggleInConstruction } = useContext(LocationsContext);
    const { tenants, isTenantsDone } = useContext(TenantContext);
    const {mollineOrganization} = useContext(OrganizationContext)

    const locationsChanged = useCompare(locations);
    const tenantsChanged = useCompare(tenants);
    const groupLocationsChanged = useCompare(groupLocations)
    const isTenantDoneChanged = useCompare(isTenantsDone)

    useEffect(() => {
        if (locationsChanged === true || tenantsChanged === true || groupLocationsChanged || isTenantDoneChanged) {
            initializeDT()
        }
    }, [tenants, locations, mollineOrganization, locationsChanged, groupLocationsChanged, tenantsChanged, isTenantDoneChanged]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const initializeDT = () => {
        if (!!locations && locations.length > 0) {
            if ($.fn.dataTable.isDataTable(`#${props.id}`)) {
                $(`#${props.id}`).DataTable().destroy();
                // remove the eventhandler to prevent multiple events
                $(`#${props.id} tbody`).off('click')
                $(`#${props.id} tbody`).off('mouseenter')
                $(`#${props.id} tbody`).off('mouseleave')
                initializeDT()
            } else {
                $(`#${props.id}`).DataTable({
                    language: de,
                    paging: true,
                    pagingType: "full",
                    processing: true,
                    searching: true,
                    data: (!!props.locations)? props.locations:locations,
                    orderCellsTop: true,
                    fixedHeader: true,
                    rowId: "_id",
                    pageLength: 100,
                    order: [[5, "asc"]],
                    columns: [
                        {
                            "data": null,
                            "searchable": false,
                            "ordering": true,
                            render: function (data, type, row) {
                                return locationIcon({location:data})
                            }
                        },

                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                return data._id;
                            },
                            visible: hasPermission({ roles: ["realm_admin", "realm_manager"], user: user}) ? true : false

                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data.token) return data.token 
                                else return  '';
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: (data, type, row) => {
                                if(!isTenantsDone){
                                    return `<div class="spinner-border" role="status"><span class="visually-hidden">Lädt...</span></div>`
                                }
                                if(data.type !== 'multi_premise'){
                                    let tenantsArray = tenants.filter(tenant => tenant.location_id === data._id.toString())
                                    let today = new Date().getTime();
                                    let tenant = null;
                                    if(tenantsArray.length === 0) tenant = "Leerstand";
                                    if(tenantsArray.length === 1) tenant = `<span><a class="connectLink" data-href="/details/tenant/${tenantsArray[0]._id}" href="#">${capitalizeUtil.capitalize(tenantsArray[0].first_name)} ${capitalizeUtil?.capitalize(tenantsArray[0].last_name)}</a></span>`
                                    //Get curent Tenant by comparing dates
                                    if(tenantsArray.length > 1){
                                        for(let t of tenantsArray){
                                            let in_date, out_date;
                                            (!!t.in_date)?in_date = new Date(t.in_date).getTime() : in_date = null;
                                            (!!t.out_date)?out_date = new Date(t.out_date).getTime() : out_date = null;
                                            if(!!in_date && in_date < today){
                                                if(!out_date || out_date > today) tenant = `<span><a class="connectLink" data-href="/details/tenant/${t._id}" href="#">${capitalizeUtil.capitalize(t.first_name)} ${capitalizeUtil.capitalize(t.last_name)}</a></span>`
                                            }
                                        }
                                    }
                                    return tenant;
                                }else{return ""}
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.street !== undefined) {
                                    return capitalizeUtil.capitalize(data.address.street)
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.house_number !== undefined) {
                                    return data.address.house_number
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.street1 !== undefined) {
                                    return data.address.street1
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.city !== undefined) {
                                    return capitalizeUtil.capitalize(data.address.city)
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.post_code !== undefined) {
                                    return data.address.post_code
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data?.attributes !== undefined && data?.attributes.includes('construction')) {
                                    return `<div><i class="text-success font-icon-custom-size fa-solid fa-wrench inConstruction" style="margin-left: 50%"></i></div>`
                                } else {
                                    return `<div><i class="text-danger font-icon-custom-size fa-solid fa-wrench inConstruction" style="margin-left: 50%"></i></div>`
                                }
                            },
                            visible: hasPermission({ roles: ["realm_admin", "realm_manager"], user: user}) ? true : false
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data?.attributes !== undefined && data?.attributes.includes('billing')) {
                                    return `<div><i class="text-success font-icon-custom-size fa-solid fa-file-invoice billingCustomer" style="margin-left: 50%"></i></div>`
                                } else if (data?.attributes !== undefined && data?.attributes.includes('no_billing')) {
                                    return `<div><i class="text-danger font-icon-custom-size fa-solid fa-file-invoice billingCustomer" style="margin-left: 50%"></i></div>`
                                } else {
                                    return `<div><i class="text-primary font-icon-custom-size fa-solid fa-file-invoice billingCustomer" style="margin-left: 50%"></i></div>`
                                }
                            },
                            visible: hasPermission({ roles: ["realm_admin", "realm_manager"], user: user}) ? true : false
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data?.attributes !== undefined && data?.attributes.includes('uvi_download')) {
                                    return `<div><i class="text-success font-icon-custom-size fa-solid fa-download uviDownload" style="margin-left: 50%"></i></div>`
                                } else {
                                    return `<div><i class="text-danger font-icon-custom-size fa-solid fa-download uviDownload" style="margin-left: 50%"></i></div>`
                                }
                            },
                            visible: hasPermission({ roles: ["realm_admin", "realm_manager"], user: user}) ? true : false
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data?.attributes !== undefined && data?.attributes.includes('uvi_email')) {
                                    return `<div><i class="text-success font-icon-custom-size fa-solid fa-envelope uviEmail" style="margin-left: 50%" title=${!!data.first_uvi_month ? data.first_uvi_month : '-'}></i></div>`
                                } else {
                                    return `<div><i class="text-danger font-icon-custom-size fa-solid fa-envelope uviEmail" style="margin-left: 50%" title=${!!data.first_uvi_month ? data.first_uvi_month : '-'}></i></div>`
                                }
                            },
                            visible: hasPermission({ roles: ["realm_admin", "realm_manager"], user: user}) ? true : false
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (hasPermission({ scopes: ['location.update.own', 'location.update.any', 'location.delete.any', 'location.delete.own', 'location.view.own', 'location.view.any'], user: user})) {
                                    return `
                                    <div class="dropdown b-dropdown btn-group" id="${data._id}">
                                        <button aria-haspopup="true" type="button" data-bs-toggle="dropdown" class="btnOverflowMenu btn btn-sm dropdown-toggle btn-secondary dropdown-toggle-split" >
                                            <i class="fa-solid fa-bars"></i>
                                        </button>
                                        <ul role="menu" tabindex="-1" class="dropdown-menu dropdown-menu-right" style="min-width: 175px;">
                                            ${
                                        (hasPermission({scopes: ['location.view.any', 'location.view.own'], user: user})) ?
                                            `<li role="presentation" class="viewLocation">
                                                    <a role="menuitem" class="dropdown-item"><i class="text-secondary fa-solid fa-magnifying-glass me-25"></i> Details</a>
                                                </li>`:''
                                    }
                                            
                                    ${(hasPermission({roles: ['realm_admin', 'realm_manager'], user: user})) ? `<hr/>` : ''}
                                    ${
                                        (hasPermission({roles: ['realm_admin', 'realm_manager'], user: user}) && (props.type === undefined || props.type === "locations")) ?
                                            `
                                                <li role="presentation" class="delLocation">
                                                    <a role="menuitem" class="dropdown-item"><i class="text-danger fa-solid fa-trash me-25"></i> Löschen</a>
                                                </li>
                                                ` : ''
                                    }

                                    ${
                                        (hasPermission({roles: ['realm_admin', 'realm_manager'], user: user}) && (props.type === "children" || props.type === "parents")) ?
                                            `
                                                <li role="presentation" class="delRelation">
                                                    <a role="menuitem" class="dropdown-item"><i class="text-danger fa-solid fa-trash me-25"></i>Beziehung Löschen</a>
                                                </li>
                                                ` : ''
                                    }
                                    ${
                                        (hasPermission({roles: ['realm_admin', 'realm_manager'], user: user}) && (data.type === "multi_premise")) ?
                                            `
                                                <li role="presentation" class="moveLocation">
                                                    <a role="menuitem" class="dropdown-item"><i class="fa-solid fa-arrow-up-from-bracket me-25"></i></i>Umziehen</a>
                                                </li>
                                                ` : ''
                                    }
                                    ${
                                        (hasPermission({roles: ['realm_admin', 'realm_manager'], user: user}) && (data.type === "multi_premise")) ?
                                            `
                                                <li role="presentation" class="deleteCompleteLocation">
                                                    <a role="menuitem" class="dropdown-item"><i class="text-danger fa-solid fa-house-circle-xmark me-25"></i></i>Ganz Löschen</a>
                                                </li>
                                                ` : ''
                                    }
                                            
                                        </ul>
                                    </div>`

                                }
                                return '';
                            }
                        },
                    ],
                    createdRow: function (row, data, dataIndex) {
                        $(row).attr('data-location_id', data._id || 'na');
                        //       $(row).addClass('alert-warning');
                    },
                    "fnDrawCallback": function( oSettings ) {}
                });

                $('.dataTables_length select').addClass('form-select');
                $('.dataTables_filter input').addClass('form-control');

                if(!hasPermission({user, roles:['realm_admin', 'realm_manager', 'god']})) {
                    $(`#${props.id} i.uviDownload`).addClass('disabled-icon');
                }
                if(!hasPermission({user, roles:['realm_admin', 'realm_manager', 'god']})) {
                    $(`#${props.id} i.uviEmail`).addClass('disabled-icon');
                }

                $(`#${props.id} tbody tr td`).off('click').on('click', function (e) {
                    const tagName = e.target.tagName;
                    if(tagName === "TD") {
                        let id = e.target.closest('tr').id;
                        router.push(`/details/location/${id}`);
                    }
                });

                $(`#${props.id} tbody`).on('mouseenter', 'tr', function () {
                    $(this).css('cursor', 'pointer');
                    $(this).css('background-color', '#f3f2f7');
                });

                $(`#${props.id} tbody`).on('mouseleave', 'tr', function () {
                    $(this).css('background-color', 'transparent');
                });

                $(`#${props.id} tbody`).on('click', 'tr', function () {
                    //$(this).toggleClass('selected');
                });
                
                $(`#${props.id} tbody`).on('click', 'a.connectLink', function (e) {
                    e.preventDefault()
                    let link = e.currentTarget.getAttribute("data-href");
                    router.push(link);
                });

                $(`#${props.id} tbody`).on('click', 'li.viewLocation', function (e) {
                    //e.preventDefault()
                    let link = e.currentTarget;
                    let parent = link.closest('tr');
                    //hideMenus();
                    router.push(`/details/location/${parent.id}`);
                });
                /* UVI-Download click Handler */

                $(`#${props.id} tbody`).on('click', 'i.uviDownload', function (e) {

                    if(hasPermission({user, roles:['realm_admin', 'realm_manager', 'god']})){
                        //e.preventDefault()
                        let link = e.currentTarget;
                        let parent = link.closest('tr');
                        let currLocation = locations.filter(l => l._id ==  parent.id)[0];
                        let shouldDownloadUvi = currLocation?.attributes.includes('uvi_download')
                        toggleUviAttribute({ locationToUpdate: currLocation  , type: 'download', shouldDownloadUvi: shouldDownloadUvi == false ? true : false, })
                    }
                });

                /* UVI-Email click Handler */

                $(`#${props.id} tbody`).on('click', 'i.uviEmail', function (e) {
                    if(hasPermission({user, roles:['realm_admin', 'realm_manager', 'god']})){
                        //e.preventDefault()
                        let link = e.currentTarget;
                        let parent = link.closest('tr');
                        let currLocation = locations.filter(l => l._id ==  parent.id)[0];
                        let shouldGetUvi = currLocation?.attributes.includes('uvi_email')
                        toggleUviAttribute({ locationToUpdate: currLocation  , type: 'email', shouldGetUvi: shouldGetUvi == false ? true : false, })
                    }
                });

                $(`#${props.id} tbody`).on('click', 'i.billingCustomer', function (e) {
                    if(hasPermission({user, roles:['realm_admin', 'realm_manager', 'god']})){
                        //e.preventDefault()
                        let link = e.currentTarget;
                        let parent = link.closest('tr');
                        let currLocation = locations.filter(l => l._id ==  parent.id)[0];
                        let isBillingCustomer = currLocation?.attributes.includes('billing')
                        toggleBillingCustomer({ locationToUpdate: currLocation, shouldBeBillingCustomer: isBillingCustomer == false ? true : false, })
                    }
                });

                $(`#${props.id} tbody`).on('click', 'i.inConstruction', function (e) {
                    if(hasPermission({user, roles:['realm_admin', 'realm_manager', 'god']})){
                        //e.preventDefault()
                        let link = e.currentTarget;
                        let parent = link.closest('tr');
                        let currLocation = locations.filter(l => l._id ==  parent.id)[0];
                        let isInConstruction = currLocation?.attributes.includes('construction')
                        toggleInConstruction({ locationToUpdate: currLocation, shouldBeInConstruction: isInConstruction == false ? true : false, })
                    }
                });

                $(`#${props.id} tbody`).on('click', 'li.delLocation', function (e) {
                    //e.preventDefault()
                    let link = e.currentTarget;
                    let parent = link.closest('tr');
                    deleteLocation(locations.filter(l => l._id ==  parent.id)[0]).then(()=>{});
                });

                $(`#${props.id} tbody`).on('click', 'li.moveLocation', function (e) {
                    //e.preventDefault()
                    let link = e.currentTarget;
                    let parent = link.closest('tr');
                    moveLocation(parent.id)
                });

                $(`#${props.id} tbody`).on('click', 'li.deleteCompleteLocation', function (e) {
                    //e.preventDefault()
                    let link = e.currentTarget;
                    let parent = link.closest('tr');
                    deleteCompleteLocation(parent.id)
                });

                $(`#${props.id} tbody`).on('click', 'li.delRelation', function (e) {
                    //e.preventDefault()
                    let link = e.currentTarget;
                    let parent = link.closest('tr');
                    // deleteLocation(locations.filter(l => l._id ==  parent.id)[0]).then(()=>{});
                    if(props.type === 'children') {
                        removeChildLocation({target_id: location._id, child_id: parent.id}).then(() => {})
                    }

                    if(props.type === 'parents') {
                        removeParentLocation({target_id: location._id, parent_id: parent.id}).then(() => {})
                    }
                });

                $(`#${props.id} tbody`).on('click', 'li.tenantMoveIn', function (e) {
                    //e.preventDefault()
                    let link = e.currentTarget;
                    let parent = link.closest('tr');

                });

                $(`#${props.id} tbody`).on('click', 'li.tenantMoveOut', function (e) {
                    //e.preventDefault()
                    let link = e.currentTarget;
                    let parent = link.closest('tr');

                });
            }
        }
    }

    const UI = () => {
        const isProcessing = () => {
            if (locations.length === 0) {
                return (
                    <div className="mt-4">
                        <h4 className="text-center"><i class="fa-solid fa-cog fa-spin"></i> WIRD AKTUALISIERT ...</h4>
                    </div>
                )
            }
        }
        return (
            <Fragment>
                <div className='table-responsive'>
                    <table id={props.id} className="table mb-0 row-border hover order-column w-100" role="grid">
                        <thead>
                            <tr>
                                <th></th>
                                <th>ID</th>
                                <th>Interne Nummer</th>
                                <th>Nutzer</th>
                                <th>Straße</th>
                                <th>Hausnr.</th>
                                <th>ME</th>
                                <th>Stadt</th>
                                <th>PLZ</th>
                                <th className='text-center'>In Bearbeitung</th>
                                <th className='text-center'>Abrechnungskunde</th>
                                <th className='text-center'>uVi-Download</th>
                                <th className='text-center'>uVi-Versand</th>
                                <th></th>
                            </tr>
                        </thead>
                    </table>
                </div>
                {isProcessing()}
            </Fragment>
        )
    }
    return (
        <Fragment>{UI()}</Fragment>
    )
}

export default TblLocations;
import React, {Fragment, useEffect, useContext, useState } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import {SensorsContext} from "../../../../context/Devices/Sensors";
import { LocationsContext } from "../../../../context/Locations/Locations";

import { CSVLink, CSVDownload } from "react-csv";
import { Buffer } from "buffer";
import Swal from "@molline/sweetalert2";
import Axios_client from "../../../../utils/axios_client";
import { PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership } from "../../reactPermissionsWrapper";
import HelpProvider from "../../helpProvider/helpProvider";
import capitalizeUtils from "../../../../utils/capitalizeUtils";
import {GroupsContext} from "../../../../context/Groups/Groups";


const ReportSensorMeasurementsWidget = (props) => {

    const { user } = useContext(AuthContext);
    const { locationsChanged, location, locations, locationIcon, locationChanged, setLocation } = useContext(LocationsContext);
    const { sensors, sensorsChanged, sanitizedSensor } = useContext(SensorsContext);
    const { groups, group, createGroup, updateGroup, deleteGroup, groupIcon } = useContext(GroupsContext)


    useEffect(  () => {

    }, [ sensors, sensorsChanged ]);

    const capitalize = (str) => {
        if (!str) return str;
        if (str.indexOf("-") > -1)
            return str.split("-").map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join("-")
        return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
    }

   /* const getLocationAddress = () => {
        return `${capitalize(location?.address?.street)} ${location?.address?.house_number}, ${(location?.address?.street1 !== '') ? `Mieteinheit ${location?.address?.street1}, ` : ''}${location?.address?.post_code} ${capitalize(location?.address?.city)}, ${location?.address?.country.toLocaleUpperCase()}`
    }*/

    const getLocationAddress = (loc) => {
        if (!loc) return;
        return `${capitalizeUtils.capitalize(loc?.address?.street)} ${loc?.address?.house_number}, ${(loc?.address?.street1 !== '') ? `Mieteinheit ${loc?.address?.street1}, ` : ''}${loc?.address?.post_code} ${capitalizeUtils.capitalize(loc?.address?.city)}, ${loc?.address?.country.toLocaleUpperCase()}`
    }

    const reportHelpHandler = (title, descriptionText) => {
        Swal.fire({
            icon: "info",
            title: title,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            confirmButtonText: 'Ok',
            text: descriptionText,
        })
    }


    const handleLocationChange = (e) => {
        const groupSelectElement = document.getElementById('locationsGroupSelect');
        groupSelectElement.value = null;

        const isReportZippedCheckboxElement = document.getElementById('isReportZippedCheckbox');
        isReportZippedCheckboxElement.checked  = false;
        isReportZippedCheckboxElement.disabled = true;

    }
    const handleGroupChange = () => {
        const locationSelectElement = document.getElementById('locationSelect');
        locationSelectElement.value = null;

        const isReportZippedCheckboxElement = document.getElementById('isReportZippedCheckbox');
        isReportZippedCheckboxElement.disabled = false;

    }



    const downloadSensorMeasurementsReport = () => {
        let orderedLocs = [...locations]

        orderedLocs.sort(function (a, b) {
            const bLocString = `${b.address.street}${a.address.house_number}}${b.address.street1 === "" ? "" : b.address.street1}`
            const aLocString = `${a.address.street}${b.address.house_number}}${a.address.street1 === "" ? "" : a.address.street1}`
            return aLocString.localeCompare(bLocString, undefined, {
                numeric: true
            })
        });


        const locsOptions = orderedLocs.map(loc => (
            `<option key=${loc._id}  value=${loc._id}>` +
            `<span>${locationIcon({ location: loc, size: 2 })}</span>` +
            `${getLocationAddress(loc)}` +
            `</option>`
        )).join('');

        const groupsOptions = groups.map(group => (
            `<option key=${group._id}  value=${group._id}>` +
            `<span>${groupIcon({ group: group, size: 2 })}</span>` +
            `${group.name}` +
            `</option>`
        )).join('');


        document.getElementById('report_tab_close').click();
        let html = `
             
            <p style="text-align:left">Laden Sie hier einen Bericht zu den Messwerten herunter. Sie können einzelne Zähler und/oder einen Zeitraum wählen.</p>
            
           <span>Nutzer-/Mieteinheit: </span>
           <select class="form-select" name="type" id="locationSelect" > 
                <option selected={location._id === loc._id} value={loc._id}>${getLocationAddress(location)}</option>
                ${locsOptions}
           </select>
         <br/>
          <h6>Oder</h6>
          
          <br />
           <span> Gruppe: </span>
           <select class="form-select" name="type" id="locationsGroupSelect" > 
             <option value="" selected disabled="true"></option>
             ${groupsOptions}
           </select>
           
            <div class="form-check" style="margin-top:5px;margin-bottom:5px">
                <input class="form-check-input" type="checkbox" value="" style="float:unset" id="isReportZippedCheckbox">
                <label class="form-check-label" for="isReportZippedCheckbox">
                    Bericht als ZIP-Archiv?
                </label>
            </div>
            <div class="form-check" style="margin-bottom:5px">
                <input class="form-check-input" type="checkbox" value=""  style="float:unset;" id="onlyLastMeasurementCheckbox">
                <label class="form-check-label" for="onlyLastMeasurementCheckbox">
                    Nur aktuellster Messwert
                </label>
            </div>
            <div class="form-check" style="margin-bottom:5px">
                <input class="form-check-input" type="checkbox" value=""  style="float:unset;" id="oneMeasurementPerDayCheckbox">
                <label class="form-check-label" for="oneMeasurementPerDayCheckbox">
                    Ein Messwert (aktuellster) pro Tag
                </label>
            </div>
        
            <input id="start_date" type="date" class="form-control form-control-lg mb-1"   /> 
            <input id="end_date" type="date" class="form-control form-control-lg mb-1"   /> 
            <input id="txtSensors" type="text" class="form-control form-control-lg mb-1" placeholder="z.B. 123,456,789"  />
            `
        Swal.fire({
            icon: "question",
            width: "35rem",
            title: `Bericht herunterladen `,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Herunterladen',
            cancelButtonText: 'Abbrechen',
            html: html,

            didOpen: () => {

                const locationSelectElement = document.getElementById('locationSelect');
                locationSelectElement.addEventListener('change', handleLocationChange);
                const groupSelectElement = document.getElementById('locationsGroupSelect');
                groupSelectElement.addEventListener('change', handleGroupChange);
            },

            preConfirm: () => {
                const start_date = Swal.getPopup().querySelector('#start_date').value
                const end_date = Swal.getPopup().querySelector('#end_date').value
                const sensorsList = Swal.getPopup().querySelector('#txtSensors').value

                const selected_location_id = Swal.getPopup().querySelector('#locationSelect').value

                const selected_group_id = Swal.getPopup().querySelector('#locationsGroupSelect').value;
                const isGroupReportZipped  = document.getElementById('isReportZippedCheckbox').checked;
                const onlyLastMeasurement  = document.getElementById('onlyLastMeasurementCheckbox').checked;
                const oneMeasurementPerDay  = document.getElementById('oneMeasurementPerDayCheckbox').checked;

                if(selected_location_id?.length <=0 && selected_group_id?.length <=0) {
                    Swal.showValidationMessage(`Bitte wählen Sie zuerst entweder eine Nutzer-/Mieteinheit oder eine Gruppe aus.`)
                }

                return {
                    start_date,
                    end_date,
                    sensorsList,
                    selected_location_id,
                    selected_group_id,
                    isGroupReportZipped,
                    onlyLastMeasurement,
                    oneMeasurementPerDay
                }
            }


        })
        .then(async (results) => {
            if (!results.isConfirmed || results.isDenied || results.isDismissed) return;
            let params = {
                returnbuffer: true,
              //  location_id: location._id,
                location_id: results.value.selected_location_id !== "{loc._id}" ? results.value.selected_location_id: location._id ,
                group_id: results.value.selected_group_id,
                isGroupReportZipped: results.value.isGroupReportZipped
            }

            if(results.value.start_date.length > 0) params.start_date = results.value.start_date;
            if(results.value.end_date.length > 0) params.end_date = results.value.end_date;
            if(results.value.sensorsList.length > 0) params.sensors = results.value.sensorsList;

            if(results.value.selected_group_id.length > 0) params.group_id = results.value.selected_group_id;
            if(results.value.isGroupReportZipped) params.isGroupReportZipped = results.value.isGroupReportZipped;
            if(results.value.onlyLastMeasurement) params.onlyLastMeasurement = results.value.onlyLastMeasurement;
            if(results.value.oneMeasurementPerDay) params.oneMeasurementPerDay = results.value.oneMeasurementPerDay;

            Axios_client.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/report/report/sensor/measurements`, params)
                    .then(response => {
                        /**
                         let fileName, buffer, blob, fileURL, link;
                         fileName = `Sensor_Measurements_Report_${new Date().getTime()}.csv`
                         buffer = Buffer.from(response.data, "utf-8")
                         blob = new Blob([buffer], { type: 'text/csv' })
                         fileURL = URL.createObjectURL(blob);
                         link = document.createElement('a');
                         link.href = fileURL;
                         link.setAttribute('download', fileName);
                         link.setAttribute('target', '_blank');
                         document.body.appendChild(link);
                         link.dispatchEvent(
                         new MouseEvent('click', {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        })
                         );
                         link.remove();
                         **/
                    })
                    .catch(err => {
                        console.log(err)
                    })


        })

    }

    const UI = () => {
       
        return (
            <PermissionsWrapper user={user} scopes={['sensor.view.any', 'sensor.view.own']} >
                <MembershipWrapper user={user} location={location} memberships={['basic', 'advanced', 'premium']} >
                    <div className="col-xl-4 col-md-6" id="widgetReportSensorMeasure">
                        <div className="card text-center clickable" >
                            <HelpProvider reportSampleImgPath="/theme2/app-assets/images/reports/sensor_measurement_report.jpg" title="Messbericht für Zähler" descriptionText="Laden Sie hier einen Bericht zu den Messwerten von allen Zählern herunter" />
                            <div className="card-body" onClick={() => {downloadSensorMeasurementsReport()}}>
                                <div className="mx-auto my-1">
                                    <i className="fa-solid fa-download font-warning fa-4x"></i>
                                </div>
                                <p className="text-muted mb-0 line-ellipsis"
                                    title="">Messbericht für Zähler</p>
                            </div>
                        </div>
                    </div>
                </MembershipWrapper>
            </PermissionsWrapper>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default ReportSensorMeasurementsWidget;
import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import {useRouter} from "../../../Shared/Router/Router";
import { OrganizationContext } from '../../../../context/Organization/Organization';
import de from "../datatable.de.json"

import $ from "jquery";
$.DataTable = require('datatables.net');

const TblGatewayAudit = (props) => {
    const router = useRouter()
    const [filteredData, setFilteredData] = useState([])
    const { organizations } = useContext(OrganizationContext);

    useEffect(() => {
            initializeDT()

    }, [props.data, filteredData, organizations]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }


    const refreshDataTables = () => {
        $('#tblGatewayAudit').DataTable().destroy();
        $('#tblGatewayAudit').off('click', 'button.btnOverflowMenu')
        $('#tblGatewayAudit').off('click', 'li.destroyTarget')
        $(`#tblGatewayAudit tbody`).off('click')
        initializeDT();
    }

    const initializeDT = () => {
            let table;
            if ($.fn.dataTable.isDataTable('#tblGatewayAudit')) {
                refreshDataTables()
            } else {
                table = $('#tblGatewayAudit').DataTable({
                    "language": de,
                    "processing": true,
                    data: props.data,
                    rowId: "uid",
                    pageLength: 100,
                    "lengthMenu": [[100, 250, -1], [100, 250, "Alle"]],
                    order: [],
                    columnDefs: [
                        {"className": "text-center", "targets": [0,1,2,3,4,5,6,7]},
                    ],
                    columns: [
                        {
                            "data": null,
                            "searchable": true,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                return `<span>${data.uid}<a class="connectLink" style="margin-left: 5px" data-href="/details/gateway/${data.uid}" href="#"><i class="fa-solid fa-arrow-up-right-from-square"></i></a></span>`

                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                let orgArray = organizations.filter(org => org._id === data.organization_id.toString())
                                if(orgArray.length !== 0) return `<span>${orgArray[0].name}</span>`
                                return '-'
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                let cssClass = 'text-primary'
                                if(data.stats.rssi === 'GREAT' || data.stats.rssi === 'GOOD') cssClass = 'text-success'
                                if(data.stats.rssi === 'OK') cssClass = 'text-warning'
                                if(data.stats.rssi === 'BAD') cssClass = 'text-danger'
                                return `<span class='${cssClass}'>${data.stats.rssi_value}</span>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.invalid === true) {
                                    return `<i class="fa-regular fa-regular fa-circle-exclamation alert-danger"></i>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.lowBattery === true) {
                                    return `<span>${data?.errors?.lowBattery[0].value}% <i class="fa-regular fa-circle-exclamation alert-danger"></i></span>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.transmissionLost === true) {
                                    return `<span>${data?.errors?.transmissionLost[0].value} <i class="fa-regular fa-circle-exclamation alert-danger"></i></span>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.noTelegrams === true) {
                                    return `<i class="fa-regular fa-circle-exclamation alert-danger"></i>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        // {
                        //     "data": null,
                        //     "searchable": false,
                        //     "visible": true,
                        //     "ordering": true,
                        //     render: function (data, type, row) {
                        //         if(data?.flags?.notUpdated === true) {
                        //             return `<i class="fa-regular fa-circle-exclamation alert-danger"></i>`
                        //         }
                        //         return `<i class="fa-regular fa-ban alert-success"></i>`
                        //     }
                        // },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.gateway_location === true) {
                                    return `<i class="fa-regular fa-circle-exclamation alert-danger"></i>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                    ],
                });

                $(`#tblGatewayAudit tbody`).on('click', 'a.connectLink', function (e) {
                    e.preventDefault()
                    let link = e.currentTarget.getAttribute("data-href");
                    window.open(link, "_blank", "noopener,noreferrer");
                });
            }
    }

    const UI = () => {
        const isProcessing = () => {
            if(props.data === 0){
              return (
                <div className="mt-4">
                  <h4 className="text-center"><i class="fa-solid fa-cog fa-spin"></i> WIRD GELADEN ...</h4>
                </div>
              )
            }
        }

        return (
            <Fragment>
                <div className="table-responsive" style={{ height: "1000px", overflowY: "auto" }}>
                    <table id="tblGatewayAudit" className="table mb-0 row-border order-column " role="grid">
                        <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f8f8", zIndex: 1 }}>
                            <tr>
                                <th className='text-center' style={{minWidth: "120px"}}>UID</th>
                                <th className='text-center'>Kunde</th>
                                <th className='text-center'>RSSI</th>
                                <th className='text-center'>Validierungs-Fehler</th>
                                <th className='text-center'>Niedrige Batterie</th>
                                <th className='text-center'>Kontakt verloren <i class="fa-regular fa-circle-info" style={{"margin-left": "5px"}} role="button" title="Gateway hat seit über 20 Tagen keine Telegramme empfangen"></i></th>
                                <th className='text-center'>Keine Telegramme <i class="fa-regular fa-circle-info" style={{"margin-left": "5px"}} role="button" title="Gateway hat noch keine Telegramme empfangen"></i></th>
                                {/* <th className='text-center'>Keine Updates</th> */}
                                <th className='text-center'>Falscher Kunde <i class="fa-regular fa-circle-info" style={{"margin-left": "5px"}} role="button" title="Gateway sieht keine Zähler vom zugewiesenen Kunden"></i></th>
                            </tr>
                        </thead>
                    </table>
                    {isProcessing()}
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )



}

export default TblGatewayAudit;
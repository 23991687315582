import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import Swal from '@molline/sweetalert2'
import {useRouter} from "../../../Shared/Router/Router";
import { OrganizationContext } from '../../../../context/Organization/Organization';
import de from "../datatable.de.json"

import $ from "jquery";
$.DataTable = require('datatables.net');

const TblSensorAudit = (props) => {
    const router = useRouter()
    const [filteredData, setFilteredData] = useState([])
    const { organizations } = useContext(OrganizationContext);

    useEffect(() => {
            initializeDT()

    }, [props.data, filteredData, organizations]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }


    const refreshDataTables = () => {
        $('#tblSensorAudit').DataTable().destroy();
        $('#tblSensorAudit').off('click', 'button.btnOverflowMenu')
        $('#tblSensorAudit').off('click', 'li.destroyTarget')
        $(`#tblSensorAudit tbody`).off('click')
        initializeDT();
    }

    const showErrorModal = (error) => {
        let errorString = ''
        if(error instanceof Array) {
            for (const err of error) {
                errorString += `${err.value} <br>`
            }
        } else {
            errorString = error.value
        }
        Swal.fire({
            title: "Fehlermeldungen",
            icon: "info",
            html: errorString,
            showCloseButton: true,
            showCancelButton: false,
            width: 1000 
        });
    }

    const initializeDT = () => {
            let table;
            if ($.fn.dataTable.isDataTable('#tblSensorAudit')) {
                refreshDataTables()
            } else {
                table = $('#tblSensorAudit').DataTable({
                    fixedHeader: true,
                    "language": de,
                    "processing": true,
                    data: props.data,
                    rowId: "uid",
                    pageLength: 100,
                    "lengthMenu": [[100, 250, -1], [100, 250, "Alle"]],
                    order: [],
                    columnDefs: [
                        {"className": "text-center", "targets": [0,1,2,3,4,5,6,7, 8, 9, 10, 11, 12]},
                    ],
                    columns: [
                        {
                            "data": null,
                            "searchable": true,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                return `<span>${data.serial_number}<a class="connectLink" style="margin-left: 5px" data-href="/details/sensor/${data.serial_number}" href="#"><i class="fa-solid fa-arrow-up-right-from-square"></i></a></span>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                let orgArray = organizations.filter(org => org._id === data.organization_id.toString())
                                if(orgArray.length !== 0) return `<span>${orgArray[0].name}</span>`
                                return '-'
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                let cssClass = 'text-primary'
                                if(data.stats.rssi === 'GREAT' || data.stats.rssi === 'GOOD') cssClass = 'text-success'
                                if(data.stats.rssi === 'OK') cssClass = 'text-warning'
                                if(data.stats.rssi === 'BAD') cssClass = 'text-danger'
                                return `<span class='${cssClass}'>${data.stats.rssi_value}</span>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.invalid === true) {
                                    return `<i class="fa-regular fa-regular fa-circle-exclamation alert-danger"></i>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.notUpdated === true) {
                                    return `<i class="fa-regular fa-regular fa-circle-exclamation alert-danger"></i>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.noMeasurements === true) {
                                    return `<i class="fa-regular fa-regular fa-circle-exclamation alert-danger"></i>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.transmissionLost === true) {
                                    return `<span>${data?.errors?.transmissionLost[0]?.value} <i class="fa-regular fa-circle-exclamation alert-danger"></i></span>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.decryptionError === true) {
                                    return `<i class="fa-regular fa-regular fa-circle-exclamation alert-danger"></i>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.nullMeasurement === true) {
                                    return `<i class="fa-regular fa-regular fa-circle-exclamation alert-danger"></i>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.errorReport === true) {
                                    return `<i class="fa-regular fa-regular fa-circle-exclamation alert-danger"></i>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.handicap === true) {
                                    return `<i class="fa-regular fa-regular fa-circle-exclamation alert-danger"></i>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.countsBackwards === true) {
                                    return `<i class="fa-regular fa-regular fa-circle-exclamation alert-danger"></i> <i class="fa-regular fa-circle-info info-modal" data-errors='${JSON.stringify(data?.errors?.countsBackwards)}' style={{"margin-left": "5px"}} role="button"></i>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                        {
                            "data": null,
                            "searchable": false,
                            "visible": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if(data?.flags?.notContinous === true) {
                                    return `<i class="fa-regular fa-regular fa-circle-exclamation alert-danger"></i> ${data?.errors?.notContinous?.length} <i class="fa-regular fa-circle-info info-modal" data-errors='${JSON.stringify(data?.errors?.notContinous)}' style={{"margin-left": "5px"}} role="button"></i>`
                                }
                                return `<i class="fa-regular fa-ban alert-success"></i>`
                            }
                        },
                       
                    ],
                });

                $(`#tblSensorAudit tbody`).on('click', 'a.connectLink', function (e) {
                    e.preventDefault()
                    let link = e.currentTarget.getAttribute("data-href");
                    window.open(link, "_blank", "noopener,noreferrer");
                });

                $(`#tblSensorAudit tbody`).on('click', 'i.info-modal', function (e) {
                    console.log('here');
                    e.preventDefault()
                    let err = e.currentTarget.getAttribute("data-errors");
                    if(err) err = JSON.parse(err)
                    showErrorModal(err)
                });
            }
    }

    const UI = () => {
        const isProcessing = () => {
            if(props.data === 0){
              return (
                <div className="mt-4">
                  <h4 className="text-center"><i class="fa-solid fa-cog fa-spin"></i> WIRD GELADEN ...</h4>
                </div>
              )
            }
        }

        return (
            <Fragment>
                <div className="table-responsive" style={{ height: "1000px", overflowY: "auto" }}>
                    <table id="tblSensorAudit" className="table mb-0 row-border order-column " role="grid">
                        <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f8f8", zIndex: 1 }}>
                            <tr>
                                <th className='text-center' style={{minWidth: "130px"}}>Seriennummer</th>
                                <th className='text-center'>Kunde</th>
                                <th className='text-center'>RSSI</th>
                                <th className='text-center'>Validierungs-Fehler</th>
                                <th className='text-center'>Keine Kalkulation/Updates (10 Tage)</th>
                                <th className='text-center'>Keine Messwerte</th>
                                <th className='text-center'>Kontakt verloren</th>
                                <th className='text-center'>Entschlüsselungs-Fehler</th>
                                <th className='text-center'>Messwert-Fehler</th>
                                <th className='text-center'>Fehlerbericht</th>
                                <th className='text-center'>Messwert abnormal</th>
                                <th className='text-center'>falsch rum eingebaut</th>
                                <th className='text-center'>zählt nicht kontinuierlich</th>
                            </tr>
                        </thead>
                    </table>
                    {isProcessing()}
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )



}

export default TblSensorAudit;
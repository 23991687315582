import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import TblGatewayAudit from '../../../../components/Dashboard/tables/dataTables/tblGatewayAudit';
import { uniqueId } from 'underscore';
import AxiosClient from '../../../../utils/axios_client';



const GatewayAudit = () => {

    const [gatewayAuditData, setGatewayAuditData] = useState([])
    const [updated, setUpdated] = useState(false)
    const [filters, setFilters] = useState({
        bad_rssi: {
            status: false,
            name: 'Schlechter RSSI'
        },
        invalid: {
            status: false,
            name: 'Validierungs-Fehler'
        },
        magnet_abused: {
            status: false,
            name: 'Magnet verwendet'
        },
        has_alarms: {
            status: false,
            name: 'Hat Alarme'
        },
        low_battery: {
            status: false,
            name: 'Niedrige Batterie'
        },
        transmission_lost: {
            status: false,
            name: 'Kontakt verloren'
        },
        no_telegrams: {
            status: false,
            name: 'Keine Telegramme'
        },
        gateway_location: {
            status: false,
            name: 'Falscher Kunde'
        }
    })
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        if (!updated) {
            setUpdated(true)
            getGatewayAuditData()
        }

    }, [filters, filteredData])

    const getGatewayAuditData = async () => {
        try {
            const result = await AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/audit/gateway`)
            setGatewayAuditData(result.data)
            setFilteredData(result.data)
        } catch (err) {
            console.log('error at getting gateway audit data', err);
        }
    }

    const updateFilter = (e) => {
        e.stopPropagation();
        const prev = { ...filters }
        prev[e.target.name].status = !prev[e.target.name].status
        setFilters(prev)
        updateFilteredData(prev)
    }

    const updateFilteredData = (newFilter) => {
        let filtered = [...gatewayAuditData]
        if (newFilter.bad_rssi.status) {
            filtered = filtered.filter(gw => gw.stats.rssi !== "GOOD" && gw.stats.rssi !== "GREAT")
        }
        if(newFilter.invalid.status) {
            filtered = filtered.filter(gw => gw.flags.invalid === true)
        }
        if(newFilter.magnet_abused.status) {
            filtered = filtered.filter(gw => gw.flags.magnet_abused === true)
        }
        if(newFilter.has_alarms.status) {
            filtered = filtered.filter(gw => gw.flags.has_alarms === true)
        }
        if(newFilter.low_battery.status) {
            filtered = filtered.filter(gw => gw.flags.lowBattery === true)
        }
        if(newFilter.transmission_lost.status) {
            filtered = filtered.filter(gw => gw.flags.transmissionLost === true)
        }
        if(newFilter.no_telegrams.status) {
            filtered = filtered.filter(gw => gw.flags.noTelegrams === true)
        }
        if(newFilter.gateway_location.status) {
            filtered = filtered.filter(gw => gw.flags.gateway_location === true)
        }
        setFilteredData(filtered)
    }

    const UI = () => {

        return (
            <Fragment>
                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Gateway-Monitoring</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a>Tools</a></li>
                                        <li className="breadcrumb-item active">Gateway-Monitoring</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>

                <div className="content-body" >

                    <section id="gatewayAudit">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Gateway-Monitoring</h4>
                                        <button aria-haspopup="true" type="button" data-bs-toggle="dropdown" className="btnOverflowMenu btn btn-primary dropdown-toggle mt-50 mb-50 dropdown-toggle-split" >
                                            Gateway-Filter
                                        </button>
                                        <ul role="menu" tabindex="-1" className="dropdown-menu dropdown-menu-left" >
                                            {
                                                Object.keys(filters).map((filterName, i) => {
                                                    return (
                                                        <li key={uniqueId()}>
                                                            <label className="dropdown-item" for={filterName}>
                                                                <input className="form-check-input input-sm" type="checkbox" id={filterName} name={filterName} checked={filters[filterName].status === true} onChange={updateFilter} />
                                                                <span className="ms-50 me-50">{filters[filterName].name}</span>
                                                            </label>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <TblGatewayAudit data={filteredData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


GatewayAudit.requiredScope = ['sensor.update.any'];
GatewayAudit.requireAuth = true;
export default GatewayAudit;

import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { useRouter } from "../../../Shared/Router/Router";
import { SensorsContext } from "../../../../context/Devices/Sensors";
import { LocationsContext } from '../../../../context/Locations/Locations';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";

import de from '../datatable.de.json';
import capitalizeUtil from '../../../../utils/capitalizeUtils'
const $ = require('jquery')
$.DataTable = require('datatables.net');


const TblSensors = (props) => {

    const router = useRouter()
    const { sensors, deleteSensor, medium_icon, sensorFlags } = useContext(SensorsContext);
    const { user } = useContext(AuthContext);
    const { locations, locationsChanged } = useContext(LocationsContext);
    const sensorsChanged = useCompare(sensors)
    const propsSensorsChanged = useCompare(props.sensors)

    useEffect(() => {
        if (sensorsChanged === true || locationsChanged === true || propsSensorsChanged === true ) initializeDT()

    }, [props, props.sensors, propsSensorsChanged, sensors, sensorsChanged, locations, locationsChanged]);


    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }



    const deleteXSensor = async ({serial_number}) => {
        try{ await deleteSensor(serial_number)}catch(err){}
    }
    /**
     * Boots datatable. The source of data is all sensors for an organization UNLESS
     * a location is provided. Then the location will be filtered and all sensors
     * relevant will be pulled
     */
    const initializeDT = async () => {
        if(!props.sensors) props.sensors = []; // so no crash
        let table;
        if ($.fn.dataTable.isDataTable(`#${props.id}`)) {
            $(`#${props.id}`).DataTable().destroy();
            // remove the eventhandler to prevent multiple events 
            $(`#${props.id} tbody`).off('click')
            $(`#${props.id} tbody`).off('mouseenter')
            $(`#${props.id} tbody`).off('mouseleave')
            initializeDT()
        } else {
            table = $(`#${props.id}`).DataTable({
                language: de,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: props.sensors,
                rowId: "serial_number",
                pageLength: 100,
                "lengthMenu": [[100, 250, -1], [100, 250, "Alle"]],
                orderCellsTop: true,
                fixedHeader: true,
                order: [[2, "asc"]],
                columns: [
                    {
                        "data": null,
                        "searchable": false,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data.attributes.includes('wmbus')) return ( '<i title="Funk-Zähler (WMBUS)" class="fa-solid fa-wifi text-success"></i>' )
                            else if(data.attributes.includes('mbus')) return ( '<i title="Drahtgebundener Zähler (MBUS)" class="fa-solid fa-diagram-nested text-primary"></i>' )
                            else if(data.attributes.includes('lora')) return ( '<i title="LORA-Zähler" class="fa-solid fa-tower-broadcast text-primary"></i>' )
                            else return ( '<i class="fa-solid fa-wifi-exclamation text-danger" title="Sensor-Typ unbekannt (kein WMBUSMBUS/LORA)"></i>' )
                        }
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "ordering": false,
                        render: function (data, type, row) {
                            if (!!data.medium_code) {
                                return (`${ medium_icon({sensor:data}) } ${ sensorFlags({sensor:data}) }`)
                            } else {
                                return (`<i class="fa-solid fa-microchip"></i> ${ sensorFlags({sensor:data}) }`)
                            }
                        }
                    },
                    { "data": "serial_number", width: "10%" },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        width: "10%",
                        render: function (data, type, row) {

                            if (data.type === "UNKOWN" || !data.type) return '--';
                            return `${data.type}`;
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        width: "14%",
                        render: function (data, type, row) {
                            let date = new Date(data.state.date)
                            if(type === "display") {
                                // date of "2000-01-01T00:00:00.000Z" is from resetting the sensor before rerunning the telegram history
                                if (data.state && data.state.date !== undefined && data.state.date !== "2000-01-01T00:00:00.000Z") {
                                    var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                                    return `${date.toLocaleDateString("de-DE", options)}`;
                                } else {
                                    return '<i class="text-danger fa-solid fa-triangle-exclamation" />';
                                }
                            }else {
                                if (data.state && data.state.date !== undefined) {
                                    const unix = date.getTime() / 1000
                                    return unix
                                } else {
                                    return 0
                                }
                            }
                        },
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        width: "14%",
                        render: function (data, type, row) {
                            if (data.state !== undefined && data.state.value !== undefined) {
                                if (data.type === 'SMK') {
                                    if ([1, -1, "1", "-1"].includes(data.state.value) || !hasPermission({ scopes: ['error.view.any'], user: user})) return (`<span class="text-success">OK</span>`)
                                    else return (`<span class="text-danger">Problem</span>`)
                                }
                                return `${data.state.value}`;
                            } else {
                                return '<i class="text-danger fa-solid fa-triangle-exclamation"> No measurements</i>';
                            }
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {

                            let description = `${(!!data.alias)?data.alias:''} ${(!!data.description)?data.description:''} ${(!!data.minor_location)?data.minor_location:''}`
                            if(description.length > 1) return description
                            else return '<i class="text-danger fa-solid fa-triangle-exclamation"></i>';

                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (locations && locations.length > 0 && !!data.location_id) {
                                data.location = locations.filter(loc => loc._id === data.location_id)[0]
                                if (!!data.location) return `<a class="connectLink" data-href="${`/details/location/${data.location._id}`}" href="#" >${capitalizeUtil.capitalize(data.location.address.street)} ${data.location.address.house_number} ${(data.location.address.street1 !== '') ? ', ME ' + capitalizeUtil.capitalize(data.location.address.street1) : ''}, ${capitalizeUtil.capitalize(data.location.address.city)}, ${data.location.address.post_code}</a>`
                            }
                            return '<i class="text-danger fa-solid fa-triangle-exclamation"></i>';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (hasPermission({ scopes: ['sensor.update.own', 'sensor.update.any', 'sensor.delete.any', 'sensor.delete.own'], user: user})) {
                                return `
                                <div class="dropdown b-dropdown btn-group" id="${data._id}">
                                    <button aria-haspopup="true" type="button" data-bs-toggle="dropdown" class="btnOverflowMenu btn btn-sm dropdown-toggle btn-secondary dropdown-toggle-split" >
                                        <i class="fa-solid fa-bars"></i>
                                    </button>
                                    <ul role="menu" tabindex="-1" class="dropdown-menu dropdown-menu-right" >
                                        ${
                                            (hasPermission({ scopes: ['sensor.view.any', 'sensor.view.own'], user: user})) ?
                                            `<li role="presentation" class="viewSensor" data-serial-number="${data.serial_number}" >
                                                <a role="menuitem" class="dropdown-item"><i class="text-secondary fa-solid fa-magnifying-glass me-25"></i> Details</a>
                                            </li>`:''
                                        }
                                        ${
                                            (hasPermission({   roles: ['realm_admin', 'realm_manager'], user: user})) ?
                                            `
                                            <li role="presentation" class="sensorChangeOut">
                                                <a role="menuitem" class="dropdown-item" data-serial="${data.serial_number}"><i class="text-danger me-25 fa-solid fa-repeat "></i> Austausch</a>
                                            </li>
                                            ` : ''
                                        }
                                        <hr/>
                                        ${
                                            (hasPermission({   roles: ['realm_admin', 'realm_manager'], user: user})) ?
                                            `
                                            <li role="presentation" class="delSensor">
                                                <a role="menuitem" class="dropdown-item"><i class="text-danger fa-solid fa-trash me-25"></i> Löschen</a>
                                            </li>
                                            ` : ''
                                        }
                                    </ul>
                                </div>`
                            }
                            return '';
                        }
                    },
                ],
                createdRow: function (row, data, dataIndex) {
                    $(row).attr('data-sensor_id', data._id || 'na');
                    let date = new Date()
                    let sDate = new Date(data.state.date)
                    let hours = Math.abs(date - sDate) / 36e5;

                    if( hasPermission({user, scopes:['error.view.any']}) ) {
                        if (hours > 360) { $(row).addClass('alert-danger'); } //If no contact for 15 days, then show red
                        if (data.state.date === undefined) { $(row).addClass('alert-danger'); }
                        if (data.state?.status === 'warning') { $(row).addClass('alert-warning'); }
                        if (data.state?.status === 'defective' || data.state?.flags.includes('handicap')) { $(row).addClass('alert-dark'); }
                    }


                },
                "fnDrawCallback": function( oSettings ) { }
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $('.btnQuickFilter').on('click', function () {
                table.columns(2).search($(this.data())).draw();
            });

            $(`#${props.id} tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });

            $(`#${props.id} tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

            $(`#${props.id} tbody`).on('click', 'li.viewSensor', function (e) {
                let link = e.currentTarget;
                let parent = link.closest('tr');
                router.push(`/details/sensor/${parent.id}`);
            });

            $(`#${props.id} tbody`).on('click', 'li.delSensor', function (e) {
                let link = e.currentTarget;
                let parent = link.closest('tr');
                deleteXSensor({serial_number:parent.id}).then(()=>{});
            });

            $(`#${props.id} tbody`).on('click', 'li.sensorChangeOut', function (e) {
                let link = e.currentTarget;
                let parent = link.closest('tr');
                router.push(`/tools/sensor/change?serial_number=${parent.id}`)
                
            });

            $(`#${props.id} tbody`).on('click', 'a.connectLink', function (e) {
                e.preventDefault()
                let link = e.currentTarget.getAttribute("data-href");
                router.push(link);
            });

            $(`#${props.id} tbody tr td`).off('click').on('click', function (e) {
                const tagName = e.target.tagName;
                if(tagName === "TD") {
                    let id = e.target.closest('tr').id;
                    router.push(`/details/sensor/${id}`);
                }
            });
        }
        
    }

    const UI = () => {
        const isProcessing = () => {
            if (sensors.length === 0 && !sensorsChanged) {
                return (
                    <div className="mt-4">
                        <h4 className="text-center"><i className="fa-solid fa-cog fa-spin"></i> WIRD GELADEN ...</h4>
                    </div>
                )
            }
        }

        return (
            <Fragment>
                <div className='table-responsive'>
                    <table id={`${props.id}`} className="table mb-0 row-border hover order-column w-100 table-hover" role="grid">
                        <thead>
                            <tr>
                                <th ></th>
                                <th ></th>
                                <th >Seriennummer</th>
                                <th >Art</th>
                                <th >Zuletzt gesendet</th>
                                <th >Wert</th>
                                <th >Beschreibung</th>
                                <th >Nutzereinheit</th>
                                <th></th>
                            </tr>
                        </thead>
                    </table>
                </div>
                {isProcessing()}
            </Fragment>
        )
    }
    return (
        <Fragment>{UI()}</Fragment>
    )
}

export default TblSensors;